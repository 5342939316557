<template>
  <div>
    <payment-method-form
      v-if="paymentMethod.id"
      ref="payment-methods-form"
    />
    <div v-else>
      <div v-if="!error">
        <skeleton />
      </div>
      <div v-else>
        <component-not-found
          title="Payment Methods List"
          :button-link="{name: 'settings-payment-methods'}"
        />
      </div>
    </div>
  </div>

</template>

<script>

import Skeleton from '../components/Skeleton.vue'
import config from '../config'
import PaymentMethodForm from '../components/Form.vue'

export default {
  name: 'Update',
  components: {
    PaymentMethodForm,
    Skeleton,
  },
  inject: ['paymentMethodsModuleName'],
  data() {
    return {
      error: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['payment-methods-form']) this.$refs['payment-methods-form'].showConfirmationModal(next)
  },
  computed: {
    paymentMethod() {
      return this.$store.state[this.paymentMethodsModuleName].paymentMethodsForm
    },
    // ...mapGetters('listModule', ['getStatus']),
  },
  mounted() {
    this.$store.dispatch(`${this.paymentMethodsModuleName}/get`, this.$route.params.id).then(res => {
      const { data } = res.data
      const paymentMethod = this.mappingFields(Object.keys(this.fields), data, {
        id: data.id,
        payment_terms: data.payment_terms.map(({ id }) => id),
        payment_gateway_id: data.payment_gateway.name,
        // is_active: this.getStatus(data.is_active),
      })
      this.$store.commit(`${this.paymentMethodsModuleName}/SET_FORM`, paymentMethod)
      this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_METHODS_FORM_CLONE`, paymentMethod)
    }).catch(err => {
      this.error = true
      this.errorNotification(this, err)
    })
  },
  created() {
    this.$store.commit(`${this.paymentMethodsModuleName}/SET_FORM`, {})
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style>

</style>
