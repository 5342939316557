<template>
  <div>
    <p class="text-left font-weight-bolder font-medium-5">
      {{ $t('Payment Method') }}
    </p>
    <b-row>
      <b-col md="6">
        <div
          v-for="field in ['name', 'payment_gateway_id']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ fields[field].label }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
      <b-col md="6">
        <div
          v-for="field in ['is_active']"
          :key="field"
          style="height: 70px"
        >
          <span class="inputLabel">{{ $t('Status') }}</span>
          <b-skeleton
            height="40px"
            style="border-radius: 5px"
          />
        </div>
        <div style="height: 70px">
          <span class="inputLabel">{{ $t('Payment Terms') }}</span>
          <b-skeleton
            height="100px"
            style="border-radius: 5px"
          />
        </div>
      </b-col>
    </b-row>
  </div>

</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'
import config from '../config'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
  setup() {
    const { fields } = config()
    return {
      fields,
    }
  },
}
</script>
