<template>
  <div>
    <b-row v-if="isUpdate">
      <b-col md="12">
        <div class="d-flex justify-content-end">
          <feather-icon
            icon="LTrashIconUpdated"
            size="36"
            class="cursor-pointer"
            @click="setInactive(paymentMethod)"
          />
          <feather-icon
            v-if="organizationRequiresEditConfirmation"
            v-b-tooltip.noninteractive.hover.bottom
            :title="$t('Edit')"
            :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
            size="36"
            class="ml-1 cursor-pointer"
            @click="isEditAble()"
          />
        </div>
      </b-col>
    </b-row>
    <p class="text-left font-weight-bolder font-medium-5">
      {{ isUpdate ? $t('Payment Method') : $t('Payment Method') }}
    </p>
    <error-alert
      :fields="fields"
      :error="error"
    />
    <b-form>
      <validation-observer
        ref="paymentMethodCreateVal"
      >
        <b-row>
          <b-col
            v-for="field in ['name']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              v-model="paymentMethod[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col
            v-for="field in ['is_active']"
            :key="field"
            class="is_active"
            md="6"
          >
            <label class="inputLabel">{{ $t('Status') }}</label>
            <component
              :is="fields[field].type"
              v-model="paymentMethod[field]"
              v-bind="getProps(field)"
              class="is_active_items custom_label_padding"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col
            v-for="field in ['payment_gateway_id']"
            :key="field"
            md="6"
          >
            <component
              :is="fields[field].type"
              v-model="paymentMethod[field]"
              v-bind="getProps(field)"
              :is-editable="isEdit"
            />
          </b-col>
          <b-col>
            <span class="inputLabel">{{ $t('Payment Terms') }}</span>
            <b-form-checkbox
              v-for="({label, id}) in paymentTermsList"
              :key="label"
              class="d-flex flex-column font-weight-normal"
              :checked="isChecked(id, 'payment_terms')"
              :disabled="isEdit"
              :class="isEdit ? 'custom-checkbox-input-pt' : ''"
              @change="getCheckboxValue($event, id, 'payment_terms')"
            >{{ $t(label) }}
            </b-form-checkbox>
          </b-col>
          <!--          <div>-->
          <!--            <label class="font-weight-bolder">{{ $t('Default Payment Methods') }}</label>-->
          <!--            <div-->
          <!--              v-for="({ name, id}) in paymentTerms"-->
          <!--              :key="id + 'paymentTerms'"-->
          <!--              class="d-flex align-content-center justify-content-between"-->
          <!--            >-->
          <!--              <label-->
          <!--                :for="id.toString()"-->
          <!--                class="inputLabelNormal d-flex align-items-center"-->
          <!--              >{{ name }}</label>-->
          <!--              <b-form-checkbox-->
          <!--                :id="id.toString()"-->
          <!--                type="checkbox"-->
          <!--                :checked="isChecked(id, paymentMethod.payment_option_ids)"-->
          <!--                class="d-flex check&#45;&#45;disabled"-->
          <!--                :disabled="!$can( ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.action,ACCESS_ABILITY_FOR_SYSTEM_CUSTOMER_DEFAULTS.subject) || isEdit"-->
          <!--                @change="toggleIds(id, 'payment_option_ids')"-->
          <!--              />-->
          <!--            </div>-->
          <!--          </div>-->
        </b-row>
        <portal to="body-footer">
          <div class="d-flex mt-2 pb-1 justify-content-between">
            <div>
              <b-button
                v-if="!isFormChanged"
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="$router.push({ name: 'settings-payment-methods-list' })"
              >
                {{ $t('Back to List') }}
              </b-button>
              <b-button
                v-else
                class="cancelBtn font-medium-1 font-weight-bolder"
                variant="outline-primary"
                @click="loader"
              >
                <feather-icon
                  icon="LCancelIcon"
                  size="16"
                />
                {{ $t('Cancel') }}
              </b-button>
            </div>
            <div>
              <b-button
                variant="success"
                class="saveBtn font-medium-1 font-weight-bolder"
                :disabled="isCreateForm ? false : !isFormChanged"
                @click="submit"
              >
                {{ $t('SAVE') }}
              </b-button>
            </div>
          </div>
        </portal>
      </validation-observer>
    </b-form>
    <b-s-warning-modal ref="confirm-modal" />
  </div>
</template>

<script>

import { required } from '@/libs/validations/validations'
import ErrorAlert from '@/views/components/ErrorAlert.vue'
import { ValidationObserver } from 'vee-validate'
import {
  BForm, BRow, BCol, BButton, BFormCheckbox, VBTooltip,
} from 'bootstrap-vue'
import { scrollToError } from '@core/utils/utils'
import store from '@/store'
import config from '../config'

export default {
  name: 'PaymentMethodForm',
  components: {
    BFormCheckbox,
    ErrorAlert,
    ValidationObserver,
    BForm,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  inject: ['paymentMethodsModuleName'],
  props: {
    isCreateForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      error: {},
      onSubmit: false,
      payment_terms: [],
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
    paymentMethod() {
      return this.$store.state[this.paymentMethodsModuleName].paymentMethodsForm
    },
    paymentTermsList() {
      return this.$store.state.listModule.paymentTermList
    },
    paymentMethodClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].paymentMethodsForm
    },
    isFormChanged() {
      return JSON.stringify(this.paymentMethod) !== this.paymentMethodClone
    },
    isUpdate() {
      return this.paymentMethod.id
    },
  },
  mounted() {
    if (!this.isUpdate) this.isEdit = false
    if (this.isCreateForm) this.$store.commit(`${this.MODULE_NAME_CLONE}/SET_SETTING_PAYMENT_METHODS_FORM_CLONE`, this.paymentMethod)
  },
  created() {
    this.$store.dispatch('listModule/paymentTermList', { is_active: 1 })
  },
  methods: {
    setInactive(item) {
      this.confirmNotification(this, { id: item.id, is_active: false }, `${this.paymentMethodsModuleName}/update`,
        { text: 'It is possible to revert this', confirmButtonText: 'Yes, deactivate it' })
        .then(() => {
          this.$router.push({ name: 'settings-payment-methods-list' })
        })
    },
    isEditAble() {
      // eslint-disable-next-line no-return-assign
      return this.isEdit = !this.isEdit
    },
    isChecked(id, listName) {
      return Array.isArray(this.paymentMethod[listName]) && this.paymentMethod[listName].includes(id)
    },
    getCheckboxValue(e, id, listName) {
      if (!this.paymentMethod[listName]) {
        this.$set(this.paymentMethod, listName, [])
      }
      if (e) {
        if (!this.paymentMethod[listName].includes(id)) {
          this.paymentMethod[listName].push(id)
        }
      } else {
        this.paymentMethod[listName] = this.paymentMethod[listName].filter(item => item !== id)
      }
    },
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    submit() {
      this.error = {}
      this.onSubmit = true
      this.$refs.paymentMethodCreateVal.validate().then(success => {
        if (success) {
          const data = this.paymentMethod
          const paymentMethodData = this.mappingFields(Object.keys(this.fields), data, {
            id: data.id ?? null,
            is_active: data.is_active ? 1 : 0,
            payment_terms: data.payment_terms,
            payment_gateway_id: data.payment_gateway_id.id,
          })
          this.sendNotification(this, paymentMethodData, `${this.paymentMethodsModuleName}/${this.isUpdate ? 'update' : 'create'}`)
            .then(() => {
              this.clear()
            }).catch(err => {
              this.error = err.response?.data
            })
        } else {
          scrollToError(this, this.$refs.paymentMethodCreateVal)
        }
      })
    },
    clear() {
      this.$refs.paymentMethodCreateVal.reset()
      this.$router.push({ name: 'settings-payment-methods-list' })
    },
    loader() {
      this.$refs.paymentMethodCreateVal.reset()
      const smth = JSON.parse(this.paymentMethodClone)
      this.$store.commit('paymentMethods/SET_FORM', smth)
    },
    getProps(fieldName) {
      return {
        field: this.fields[fieldName],
        name: fieldName,
      }
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'

    const { fields } = config()
    return {
      MODULE_NAME_CLONE,
      fields,
    }
  },
}
</script>

<style lang="scss">
.custom_label_padding .checkboxGroup div:nth-child(2) {
  padding-left: 0 !important;
}
.custom-checkbox-input-pt .custom-control-input[disabled] ~ .custom-control-label,
.custom-checkbox-input-pt .custom-control-input:disabled ~ .custom-control-label {
  color: #5e5873;
}
.custom-checkbox-input-pt .custom-control-input:disabled + label::after {
  background-color: #efefef;
  border-radius: 5px;
  opacity: 0.5;
}
</style>
